var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutContentSidebar',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('h1',{staticClass:"text-h1 mt-8 mb-8"},[_vm._v("Document Search")]),_c('v-sheet',{staticClass:"mb-5 pa-5",attrs:{"elevation":"1"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Search","outlined":"","clearable":"","dense":"","value":_vm.state.context.searchQuery,"hide-details":"","aria-label":"Search bar. Apply filters below before searching."},on:{"change":function($event){return _vm.send({type: 'CHANGE_SEARCH_QUERY', payload: $event})}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{staticClass:"ui-button",attrs:{"color":"primary","depressed":"","aria-label":"Submit Search Button: Click to search."},on:{"click":function($event){return _vm.send('SUBMIT')}}},[_vm._v("Submit")])]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"value":_vm.state.context.type,"items":_vm.typesAvailable,"menu-props":{closeOnContentClick:true},"multiple":"","outlined":"","dense":"","hide-details":"","chips":"","small-chips":"","deletable-chips":"","aria-label":"Document Type Filter Dropdown: Click enter to open dropdown and use up and down arrows to navigate the dropdown options.","label":"Document Type"},on:{"input":function($event){return _vm.send({type: 'CHANGE_TYPE', payload: $event})}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-chip',{attrs:{"input-value":index,"close":true,"aria-label":("Remove " + item + " document type filter")},on:{"click:close":function($event){return _vm.removeItem(_vm.state.context.type, index)}}},[_vm._v(" "+_vm._s(item)+" ")])]}}])})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"value":_vm.state.context.status,"items":_vm.statusAvailable,"menu-props":{closeOnContentClick:true},"multiple":"","outlined":"","dense":"","hide-details":"","chips":"","small-chips":"","deletable-chips":"","aria-label":"Document Status Filter Dropdown: Click enter to open dropdown and use up and down arrows to navigate the dropdown options.","label":"Document Status"},on:{"input":function($event){return _vm.send({type: 'CHANGE_STATUS', payload: $event})}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-chip',{attrs:{"input-value":index,"close":true,"aria-label":("Remove " + (item.text) + " document status filter")},on:{"click:close":function($event){return _vm.removeItem(_vm.state.context.status, index)}}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('BaseCombobox',{attrs:{"label":"Ministry","value":_vm.state.context.ministry,"searchPromise":_vm.userGroupSearchPromise,"itemText":_vm.userGroupItemText,"dense":"","hide-details":"","aria-label":"Ministry Filter: Type to find ministry and use up and down arrows to navigate the options."},on:{"change":function($event){return _vm.send({ type: 'CHANGE_MINISTRY', payload: $event})}}})],1)],1)],1),_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('v-select',{staticClass:"ui-select",attrs:{"items":[5,10,15,20],"value":_vm.state.context.itemsPerPage,"aria-label":'Items per page filter dropdown: ' + _vm.state.context.itemsPerPage + ' per page chosen. Click enter to open and use up and down arrows to navigate the dropdown options.'},on:{"change":function($event){return _vm.send({type: 'CHANGE_ITEMS_PER_PAGE', payload: $event})}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" per page ")]}}])})],1),_c('v-data-table',{staticClass:"mb-8 ui-table",attrs:{"loading":_vm.state.matches('searching'),"items":_vm.state.context.results,"headers":[
        {text: 'Status', value: 'status'},
        {text: 'Title', value: 'title' },
        {text: 'No', value: 'number', width: '80px' },
        {text: 'Type', value: 'documentType'},
        {text: 'Created', value: 'dateCreated'},
        {text: 'Actions', value: 'actions', width: '120px', sortable: false}
      ],"items-per-page":_vm.state.context.itemsPerPage,"server-items-length":_vm.state.context.resultsTotal,"hide-default-footer":"","tabindex":"0","aria-label":("Search results table - " + (_vm.state.context.resultsTotal) + " total results")},on:{"click:row":_vm.handleRowClick,"update:options":function($event){return _vm.send({type: 'CHANGE_OPTIONS', payload: $event})}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('DocumentStatusChip',{staticClass:"ui-chip",attrs:{"status":item.status,"requiresMyInput":item.requiresMyInput,"tabindex":"0","aria-label":'Document Title: ' + item.title + '. Status: ' + (item.requiresMyInput ? 'Requires Your Action' : _vm.$t(("document.status." + (item.status))))}})]}},{key:"item.dateCreated",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("formatDateShort")(item.dateCreated)))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","aria-label":"Document Actions Menu: Click enter to open menu and use up and down arrows to navigate the menu items."}},'v-btn',attrs,false),on),[_c('BaseIcon',{attrs:{"iconName":"mdiDotsVertical","dark":""}})],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'DocumentsDetail', params: { id: item.id } },"aria-label":"View Document Button: Click to view document and document details."}},[_c('v-list-item-title',[_vm._v("View Document")])],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(item.currentSpUrl !== undefined && item.status !== 'legacy-completed'),expression:"item.currentSpUrl !== undefined && item.status !== 'legacy-completed'"}],attrs:{"href":item.currentSpUrl,"target":"_blank","aria-label":"Edit Document Button: Click to edit document content in Microsoft Word"}},[_c('v-list-item-title',[_vm._v("Edit Document")])],1)],1)],1),_c('BaseIcon',{staticClass:"ds-list-item__highlight-icon",attrs:{"iconName":"mdiChevronRight","dark":""}})]}}])}),_c('div',{staticClass:"ui-layout__pagination"},[_c('BasePagination',{attrs:{"totalPerPage":_vm.state.context.itemsPerPage,"total":_vm.state.context.resultsTotal,"length":_vm.state.context.pageCount,"page":_vm.state.context.page},on:{"input":function($event){return _vm.send({type: 'CHANGE_PAGE', payload: $event})},"pageJump":function($event){return _vm.send({type: 'CHANGE_PAGE', payload: $event})}}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }