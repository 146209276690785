import { Machine, assign, interpret } from 'xstate'
import store from '@/store'
import repositoryDocument from '@/domain/documentRepository'
import { useService } from 'xstate-vue2'

export const searchMachine = Machine<any>({
  id: 'search',
  initial: 'searching',
  context: {
    results: [],
    resultsTotal: 0,
    pageCount: 1,
    page: 1,
    itemsPerPage: 10,
    status: [],
    searchQuery: undefined,
    sort: ['number'],
    order: [true],
    type: [],
    ministry: undefined,
    error: ''
  },
  states: {
    idle: {
      on: {
        SUBMIT: {
          target: 'searching',
          actions: ['resetPage']
        },
        CHANGE_PAGE: {
          target: 'searching',
          actions: ['setPage']
        },
        CHANGE_STATUS: {
          target: 'searching',
          actions: ['setStatus', 'resetPage']
        },
        CHANGE_TYPE: {
          target: 'searching',
          actions: ['setType', 'resetPage']
        },
        CHANGE_MINISTRY: {
          target: 'searching',
          actions: ['setMinistry', 'resetPage']
        },
        CHANGE_SEARCH_QUERY: {
          target: 'searching',
          actions: ['setSearch', 'resetPage']
        },
        CHANGE_ITEMS_PER_PAGE: {
          target: 'searching',
          actions: ['setItemsPerPage', 'resetPage']
        },
        CHANGE_OPTIONS: {
          target: 'searching',
          actions: ['setOptions']
        }
      }
    },
    searching: {
      invoke: {
        src: 'search',
        onDone: {
          target: 'idle',
          actions: 'setResults'
        },
        onError: {
          target: 'idle',
          actions: 'setErrors'
        }
      }
    }
  }
}, {
  services: {
    search: async (context: any) => {
      const orderTypes = { status: undefined, title: undefined, documentType: undefined, number: undefined, dateCreated: undefined }
      let status, status2, documentType, documentType2

      if (context.sort && context.sort.length && context.order && context.order.length) {
        context.sort.forEach((value, index) => {
          orderTypes[value] = context.order[index] ? 'desc' : 'asc'
        })
      }

      if (context.status && context.status.length) {
        if (context.status.length > 1) {
          status2 = context.status
        } else {
          status = context.status[0]
        }
      }

      if (context.type && context.type.length) {
        if (context.type.length > 1) {
          documentType2 = context.type
        } else {
          documentType = context.type[0]
        }
      }

      const response = await repositoryDocument.search({
        page: context.page,
        itemsPerPage: context.itemsPerPage,
        orderStatus: orderTypes.status,
        orderTitle: orderTypes.title,
        orderDocumentType: orderTypes.documentType,
        orderNumber: orderTypes.number,
        orderDateCreated: orderTypes.dateCreated,
        status,
        status2,
        documentType,
        documentType2,
        q: context.searchQuery,
        ministry: context.ministry?.id
      })

      store.commit('documents/addDocumentsToCache', response.results)
      store.commit('documents/setDocumentIdsForSearch', response.results)

      return response
    }
  },
  actions: {
    log: (context, event) => {
      console.log(context, event)
    },
    setResults: assign({
      results: (context, event: any) => store.getters['documents/getDocumentsForSearch'],
      resultsTotal: (context, event: any) => event.data.resultsTotal,
      pageCount: (context, event: any) => event.data.pageCount
    }),
    setPage: assign({
      page: (context, event: any) => event.payload
    }),
    setItemsPerPage: assign({
      itemsPerPage: (context, event: any) => event.payload
    }),
    setStatus: assign({
      status: (context, event: any) => event.payload
    }),
    setType: assign({
      type: (context, event: any) => event.payload
    }),
    setMinistry: assign({
      ministry: (context, event: any) => event.payload
    }),
    setSearch: assign({
      searchQuery: (context, event: any) => event.payload || undefined
    }),
    setOptions: assign({
      sort: (context, event: any) => event.payload.sortBy,
      order: (context, event: any) => event.payload.sortDesc
    }),
    resetPage: assign({
      page: 1
    })
  }
})

// Create a service using the machine.
const service = interpret(searchMachine)
// Create a custom service hook for this machine, that takes the service previously created.
// This way you don't have to import on every component the useService hook and the service created.
export const useSearchMachine = () => {
  return useService(service.start())
}
