

























































































































































































import { defineComponent, inject, ref } from '@vue/composition-api'
import LayoutContentSidebar from '@/router/layouts/LayoutContentSidebar.vue'
import DocumentStatusChip from '@/components/DocumentStatusChip.vue'
import { BasePagination, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import { useSearchMachine } from '@/machines/searchMachine'
import { sidebarMachineSendKey } from '@/router/views/AppOutlet.vue'
import repositoryUserGroup from '@/domain/userGroupRepository'

export default defineComponent({
  components: {
    LayoutContentSidebar,
    BasePagination,
    BaseCombobox,
    DocumentStatusChip,
    BaseIcon
  },
  setup () {
    const statusAvailable = ref([
      { text: 'Draft', value: 'draft' },
      { text: 'Moving to Collaboration', value: 'collaboration-moving' },
      { text: 'In Collaboration', value: 'collaboration-in-progress' },
      { text: 'Moving to Approval', value: 'approval-moving' },
      { text: 'In Approval', value: 'approval-in-progress' },
      { text: 'Approved', value: 'approved' },
      { text: 'Archived', value: 'archived' },
      { text: 'Legacy Archived', value: 'legacy-completed' }
    ])
    const typesAvailable = ref([
      'Minutes',
      'Legislation & Regulation',
      'Cabinet Item',
      'Order in Council'
    ])

    const userGroupSearchPromise = q => repositoryUserGroup.findByCriteria({ q })
    const userGroupItemText = s => s.title

    const { state, send } = useSearchMachine()
    const sendSidebar = inject(sidebarMachineSendKey)

    const handleRowClick = (document) => {
      if (sendSidebar) {
        sendSidebar({ type: 'DOCUMENT_VIEW', payload: { document } })
      }
    }

    const removeItem = (array, index) => {
      array.splice(index, 1)
    }

    return {
      statusAvailable,
      typesAvailable,
      userGroupSearchPromise,
      userGroupItemText,
      state,
      send,
      handleRowClick,
      removeItem
    }
  }
})
